import React from "react";
import { createContext, Dispatch, useContext, useReducer } from "react";
import { Message } from "../typing/messages";
import { Profile } from "../typing/profile";
export type ActionProps = {
  type: string;
  payload: any;
};

type StoreProviderProps = {
  storeReducer: (prevState: any, action: ActionProps) => any;
  initialState: any;
  children?: JSX.Element | JSX.Element[] | undefined;
};

export type StoreProps = {
  profile?: Profile;
  profileLastUpdated: number;
  setupCompleted: boolean;
  user?: any;
  city?: { name: string };
  auth?: { isLoading: boolean };
  entities: {
    messages: Message[];
  };
  currentTab: number;
};

const StoreContext = createContext({} as [StoreProps, Dispatch<ActionProps>]);

export const useStore = () => useContext(StoreContext);

type Action = (prevState: StoreProps, action: ActionProps) => StoreProps;
export const StoreProvider = ({
  storeReducer,
  initialState,
  children,
}: StoreProviderProps) => {
  const store = useReducer<Action>(storeReducer, initialState);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
