import { lazy, Suspense } from "react";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};
const DeliveryScreen = Loadable(lazy(() => import("./screens/deliveries")));

const routes: any = () => {
  return [
    ...authRoutes,
    {
      path: "/",
      element: <DeliveryScreen />,
    },
    {
      path: "agent",
      element: <DeliveryScreen />,
      children: deliveriesRoutes,
    },
  ];
};

const authRoutes = [
  {
    path: "",
    element: <DeliveryScreen />,
  },
];

const deliveriesRoutes = [
  {
    path: "/agent/pickups/:uuid",
    element: <DeliveryScreen />,
  },
];
export default routes;
